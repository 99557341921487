import * as React from "react";
import LazyLoad from "react-lazyload";
import {
  MobileView,
  // MobileOnlyView,
  BrowserView,
  // TabletView,
} from "react-device-detect";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Cta from "../components/cta";
import Companies from "../components/companies";
import CompaniesMobile from "../components/companiesmobile";
import { StaticImage } from "gatsby-plugin-image";
import FuelMonitoring from "../components/fuel/fuelmonitor";
import FuelExpenses from "../components/fuel/fuelexpenses";
import FuelFaqs from "../components/fuel/fuelfaqsaccordions";
import FuelBenefits from "../components/fuel/fuelbenefits";
import ReviewSliderFuel from "../components/fuel/reviewsliderfuel";
import * as Styles from "../styles/index.module.css";
import "../styles/signal.css";
import "../styles/herofeatures.css";

const FuelPage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <ParallaxProvider>
      <Layout pageTitle="Home Page">
        {/* MetaTag */}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>
            Fuel Monitoring & Fuel Cost Management Solutions for Fleet Operators
          </title>
          <meta
            name="description"
            content="Fuel Monitoring & Management solutions designed to help fleet operators monitor, control & reduce fuel spending. KATSANA fuel solutions are designed to help you identify causes of high fuel use, control and minimize fuel wastage, and tackle possible fuel abuses such as theft."
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link rel="canonical" href="https://www.katsana.com/fuel/" />

          {/* Open Graph */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.katsana.com/fuel/" />
          <meta
            property="og:title"
            content="Fuel Monitoring & Fuel Cost Management Solutions for Fleet Operators"
          />
          <meta
            property="og:description"
            content="Fuel Monitoring & Management solutions designed to help fleet operators monitor, control & reduce fuel spending. KATSANA fuel solutions are designed to help you identify causes of high fuel use, control and minimize fuel wastage, and tackle possible fuel abuses such as theft."
          />
          <meta
            property="og:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
          <meta
            property="og:image:alt"
            content="Fuel Monitoring & Fuel Cost Management Solutions for Fleet Operators"
          />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="640" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@katsanagps" />
          <meta name="twitter:creator" content="@katsanagps" />

          <meta
            name="twitter:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />


          <script type="application/ld+json">
            {`
              [
                {
                  "@context": "https://schema.org",
                  "@graph": [
                    {
                      "@type": "Product",
                      "name": "KATSANA Fuel Monitoring System",
                      "image": "https://www.katsana.com/wp-content/uploads/2020/08/fuel-monitoring-system.jpg",
                      "description": "Real-time fuel monitoring system designed to optimize fleet fuel consumption, prevent fuel theft, and enhance operational efficiency.",
                      "brand": {
                        "@type": "Brand",
                        "name": "KATSANA"
                      },
                      "sku": "FUEL-MON-01",
                      "offers": {
                        "@type": "Offer",
                        "url": "https://www.katsana.com/fuel/",
                        "priceCurrency": "MYR",
                        "price": "299",
                        "availability": "https://schema.org/InStock"
                      },
                      "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.8",
                        "reviewCount": "134"
                      },
                      "review": [
                        {
                          "@type": "Review",
                          "author": "Nagaratnam a/l Suppiah",
                          "datePublished": "2024-12-15",
                          "reviewBody": "KATSANA's Fuel Monitoring System has helped us reduce fuel consumption by over 20% across our fleet. The real-time tracking and detailed reports make managing fuel costs much easier.",
                          "name": "Exceptional Fuel Monitoring Solution",
                          "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5",
                            "bestRating": "5"
                          }
                        },
                        {
                          "@type": "Review",
                          "author": "Faizal Khalilurrahman",
                          "datePublished": "2025-01-10",
                          "reviewBody": "Since implementing the KATSANA Fuel Monitoring System, we have detected multiple instances of fuel theft and significantly improved operational efficiency. Highly recommended for fleet operators.",
                          "name": "Great Tool for Preventing Fuel Theft",
                          "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5",
                            "bestRating": "5"
                          }
                        },
                        {
                          "@type": "Review",
                          "author": "Lim Wei Han",
                          "datePublished": "2025-02-05",
                          "reviewBody": "An excellent system that provides accurate fuel usage data and integrates seamlessly with our existing fleet management software. The support team has been very helpful too.",
                          "name": "Accurate and Reliable Fuel Tracking",
                          "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5",
                            "bestRating": "5"
                          }
                        }
                      ]
                    },
                    {
                      "@type": "FAQPage",
                      "mainEntity": [
                        {
                          "@type": "Question",
                          "name": "What is the KATSANA Fuel Monitoring System?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The KATSANA Fuel Monitoring System is a real-time fuel monitoring solution that helps companies track fuel consumption, prevent theft, and optimize operational costs."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "How does the system prevent fuel theft?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The system detects any sudden drops in fuel levels that may indicate theft and sends real-time alerts to fleet managers, enabling immediate action."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Is the system compatible with all types of vehicles?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the KATSANA Fuel Monitoring System is designed to be compatible with various commercial vehicles, including trucks, vans, buses, and more."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Can this system integrate with existing fleet management software?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, the system can integrate with most fleet management software through APIs, allowing for seamless data tracking and analysis."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Does the system provide detailed fuel usage reports?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The system offers comprehensive fuel usage reports, including consumption graphs, fuel costs, and driver efficiency analysis."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Does the system require regular maintenance?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The system is designed for minimal maintenance, but periodic inspections are recommended to ensure data accuracy and optimal performance."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "How is the system installed in vehicles?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "The fuel monitoring devices are professionally installed into the vehicle's fuel system without affecting its original functionality."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Can I access real-time monitoring data remotely?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes, all fuel monitoring data can be accessed in real-time via an online portal or mobile app, enabling continuous tracking."
                          }
                        }
                      ]
                    }
                  ]
                }  
              ]            
          `}
          </script>
        </Helmet>
        {/* Hero Area */}
        <div
          className={
            Styles.tilt +
            " bg-gradient-to-br from-[hsla(211,55%,22%,1)] via-[hsla(211,45%,16%,1)] to-[hsla(211,45%,16%,1)] pt-20 md:pt-24 xl:pt-28 md:pb-16 "
          }
        >
          {/* Set Max Width */}
          <div className="relative mx-auto max-w-primary">
            <div className="flex justify-center">
              
              {/* Hero Text & CTA */}
              <div className="flex flex-col px-8 pt-4 pb-0 md:pb-8 lg:w-full xl:max-w-[820px] z-10 items-center justify-center">
                <p className="mb-0 leading-6 md:mb-2 md:leading-8 sm:text-md text-bluegray md:max-w-lg text-center">
                  Solution for Fuel Monitoring & Fuel Cost Management
                </p>
                <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold text-white text-center leading-10 md:leading-10 lg:leading-none">
                  Complete visibility of fuel spending for your fleet
                </h1>
              </div>

            </div>

            <div className="max-w-5xl p-8 pb-4 mx-auto flex justify-center">
              <div className="h-auto w-full text-center relative">
                <div 
                  className={`${Styles.bubble1} absolute right-24 md:right-280 -top-280 md:-top-120 z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >

                  <div className="w-auto h-auto scale-75 md:scale-100 overflow-hidden">
                     <StaticImage
                        alt="katsana"
                        src="../../static/images/fuel/refuel.png"
                        className="h-auto rounded-md w-full object-cover"
                      />
                  </div>
                </div>

                <div 
                  className={`${Styles.bubble2} absolute -right-200 md:-right-50 -top-200 md:-top-50 z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <div className="w-auto h-auto scale-75 md:scale-100">
                    <StaticImage
                        alt="katsana"
                        src="../../static/images/fuel/bonded-truck.png"
                        className="h-auto md:h-auto rounded-md w-[245px] md:w-full object-cover"
                      />
                  </div>
                </div>

                <div 
                  className={`${Styles.bubble3} absolute -right-200 md:-right-50 -bottom-3 md:-bottom-12 z-10`}
                  data-sal="slide-up"
                  data-sal-duration="500"
                  data-sal-easing="ease-in"
                >
                  <div className="w-auto h-auto scale-75 md:scale-100">
                    <StaticImage
                        alt="katsana"
                        src="../../static/images/fuel/alerts.png"
                        className="h-auto md:h-auto rounded-md w-[245px] md:w-full object-cover"
                      />
                  </div>
                </div>

                <StaticImage
                  alt="katsana"
                  src="../../static/images/fuel/fuel-hero.jpg"
                  className="h-auto rounded-md w-full md:w-[750px]"
                />
              </div>
            </div>

            {/* Companies / Client */}
            <LazyLoad>
              <div className="pt-0 pb-5 md:pt-0 md:pb-0">
                <BrowserView>
                  <Companies />
                </BrowserView>

                <MobileView>
                  <CompaniesMobile />
                </MobileView>
              </div>
            </LazyLoad>

          </div>
        </div>

        {/* Fuel Monitoring */}
        <div className="bg-bluedark relative">
          <div className="bg-bluedark absolute -top-[24px] sm:-top-[39.5px] [clip-path:polygon(100%_100%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px]"></div>
          
          <div className="max-w-6xl mx-auto">
            <div>
              <div className="max-w-5xl py-1 md:py-0 lg:pt-5 lg:pb-8 mx-auto">
                <FuelMonitoring />
              </div>
            </div>
          </div>

          <div className="bg-bluedark absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* Fuel Expenses */}
        <div
          className={
            " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] pb-0 relative"
          }
        > 
          <div className="max-w-6xl mx-auto">
            {/* Fuel Monitoring */}
            <div className="max-w-5xl py-1 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <FuelExpenses/>
            </div>
          </div>
          
          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* Fuel Benefits */}
        <div
          className={
            " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] pb-0 relative"
          }
        >
          <div className="max-w-6xl mx-auto">
            {/* Fuel Monitoring */}
            <div className="max-w-5xl py-1 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <FuelBenefits />
            </div>
          </div>

          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        {/* Testimonials */}
        <div className="relative pt-8 md:pt-16 pb-0 md:pb-0 bg-[hsla(203,28%,82%,1)]">
          <div className="flex flex-col max-w-5xl px-8 py-8 mx-auto md:items-center md:flex-row md:px-0">
            <div className="flex-1 ">
              <h2 className="text-2xl sm:text-4xl font-bold text-bluetext mb-6">
                Fuel Monitoring Reviews by other fleet operators
              </h2>
              <p className="max-w-3xl mt-4 text-sm leading-6 md:text-base text-bluetext">
                Over 1000 fleet oprators in Malaysia have trusted fuel monitoring solutions by KATSANA and seen signified improvements in fuel cost and effciency.
                <br />
                <br />
                Reach out to us to start measuring and implementing fuel control for your fleet.
              </p>
            </div>

            <div className="md:ml-16">
              <div className="flex flex-col mt-8 md:items-center md:mt-0 md:text-center">
                <a
                  href="https://apps.katsana.com/get-quotation"
                  className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center md:px-12 md:py-3 md:text-base leading-0 bg-primary"
                >
                  Get Free Consultation
                </a>
                <a
                  href="tel:+60377334474"
                  className="mt-4 text-sm text-primary md:flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or call us +603 7733 4474
                  <br />
                  (Kuala Lumpur, GMT +8)
                </a>
              </div>
            </div>
          </div>
          
          <LazyLoad>
            <ReviewSliderFuel />
          </LazyLoad>

          <div className="mx-auto max-w-primary">
            <Parallax y={[-40, 5]} tagOuter="figure">
              <StaticImage
                alt="katsana"
                src="../../static/images/apad/apad-permit-renewal.webp"
                objectFit="cover"
                className="relative z-0 md:mt-[-100px] mt-[-100px]  h-[200px] md:h-auto"
              />
            </Parallax>
          </div>
        </div>

        {/* Fuel FAQ */}
        <div
          className={
            " bg-gradient-to-b from-[hsla(202,28%,77%,1)] to-[hsla(198,26%,93%,1)] mt-[-1px] md:pt-6 pb-24 md:pb-36 relative"
          }
        >
          <div className="max-w-6xl mx-auto px-8 md:px-4">
            {/* Fuel Monitoring */}
            <div className="max-w-3xl py-8 md:py-0 lg:pt-8 lg:pb-8 mx-auto">
              <FuelFaqs />
            </div>
          </div>

          <div className="skew-bottom bg-[hsla(198,26%,93%,1)] absolute -bottom-[22.5px] sm:-bottom-[38.5px] [clip-path:polygon(0%_0%,0%_100%,100%_0%)] w-full h-[24px] sm:h-[40px] z-10"></div>
        </div>

        <Cta />

      </Layout>
    </ParallaxProvider>
  );
};

export default FuelPage;
